@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

/* Reset CSS*/
:root {
    --primary-color: #04103B;
    --secondry-color: #197BBD;
    --yellow-color: #E9A73E;
    --green-color: #377934;
    --pink-color: #E75A7C;
    --white-color: #ffffff;
    --black-color: #000000;
    --base-color: #F4F4F4;
    --gray-color: #a7a7a7;
    /* --font-family: 'Arial', sans-serif; */
    --font-family: 'Nunito', sans-serif;
    --font-family2: 'Nunito', sans-serif;

}

*,
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: var(--font-family);
}

.bg-white {
    background-color: #fff;
}

.lh-0 {
    line-height: 0;
}

.d-flex {
    display: flex;
}
.gap-10{
    gap: 10px;
}
.justify-end {
    justify-content: flex-end;
}

.align-center {
    align-items: center;
}

.flex-wrap {
    flex-wrap: wrap;
}

body {
    background-color: var(--base-color);
}

.w-100 {
    width: 100% !important;
}

.h-100 {
    height: 100% !important;
}

.mhv-100 {
    min-height: 100vh !important;
}

.pe-none {
    pointer-events: none;
}

ul,
li,
ol {
    list-style: none;
}

.prft_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.circle {
    border-radius: 50%;
}

a,
a:hover {
    text-decoration: none;
}

.text {
    font-weight: 400;
    text-transform: capitalize;
    color: #000;
}

.text-right {
    text-align: right;
}

.cursor_pointer {
    cursor: pointer;
}

input:focus,
input:focus-visible,
input:focus-within,
textarea:focus,
textarea:focus-visible,
textarea:focus-within,
select:focus,
select:focus-visible,
select:focus-within {
    border: 0;
    outline: 0;
    box-shadow: none;
}

.cstm_Ctnr {
    padding: 0 50px;
}

.cstm_container {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}

.white_bx {
    display: flex;
    padding: 40px 50px;
    border-radius: 18px;
    background: #FFF;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.03);
}

.min-vh {
    min-height: 100vh;
}

.sec-padding {
    padding: 40px 0;
}

.sec-padding-top {
    padding-top: 40px;
}

.sec-padding-bottom {
    padding-bottom: 40px;
}

/* Margins */
.m-0 {
    margin: 0px !important;
}

.mt-0 {
    margin-top: 0px !important;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.mt-40 {
    margin-top: 40px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.me-10 {
    margin-right: 10px !important;
}

.ml-auto {
    margin-left: auto !important;
}

.transition {
    transition: all 0.3s ease-in-out 0.1s;
}

/* Typography */
.fz-14 {
    font-size: 14px;
    line-height: 1.5em;
}

.fz-16 {
    font-size: 16px;
    line-height: 1.5em;
}

.fz-18 {
    font-size: 18px;
    line-height: 1.5em;
}

.fz-20 {
    font-size: 20px;
    line-height: 1.5em;
}

.fz-22 {
    font-size: 22px;
    line-height: 1.5em;
}

.fz-24 {
    font-size: 24px;
    line-height: 1.5em;
}

.primary-color {
    color: var(--primary-color) !important;
}

.secondry-color {
    color: var(--secondry-color) !important;
}

.pink-color {
    color: var(--pink-color) !important;
}

.green-color {
    color: var(--green-color) !important;
}

.left {
    text-align: left !important;
}

/* Buttons */
.btn_group{
    display: flex;
    gap: 10px;
    align-items: flex-start;
}
.btn_reset {
    border: 0;
    padding: 0;
    cursor: pointer;
    background-color: #0000;
    transition: all 0.3s ease-in-out 0.1s;
}

.download_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background-color: var(--pink-color);
    color: #fff;
    padding: 4px 15px 4px 16px;
    border-radius: 20px;
    font-size: 12px;
    gap: 14px;
    cursor: pointer;
}

.download_btn img {
    max-height: 18px;
    object-fit: contain;
    object-position: center;
    cursor: pointer;
}

.upload_btn img {
    max-width: 30px;
    max-height: 30px;
    object-fit: contain;
    object-position: center;
    cursor: pointer;
}

.theme_btn,
.blue_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background-color: var(--pink-color);
    color: #fff;
    padding: 10px 26px;
    border-radius: 20px;
    font-size: 16px;
    cursor: pointer;
}

.blue_btn {
    background-color: var(--secondry-color);
    padding: 12px 25px;
    border-radius: 35px;
}

.blue_btn:hover {
    background-color: var(--pink-color);
}

.pink_btn {
    display: inline-flex;
    border-radius: 24px;
    border: 1px solid var(--pink-color);
    background: var(--pink-color);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
    padding: 9px 26px;
    color: #fff !important;
    font-size: 16px;
    transition: all 0.3s ease-in-out 0.1s;
}

.pink_btn:hover {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.pink_btn.sm {
    padding: 6px 24px;
    font-size: 0.8em;
}

.green_btn {
    background-color: var(--green-color);
    border-color: var(--green-color);
}

/* *********** */
/* Sidebar */
li a.active {
    border-left-color: #fff !important;
}

li a.active span:nth-child(2) {
    background: #fff !important;
    color: #000 !important;
}

/* Main Container */
/* Start Main container */
.main_container {
    display: flex;
}

.ctnt_wpr {
    flex: 1;
}

#logout_Btn {
    display: flex;
    align-items: center;
    gap: 8px;
}

#logout_Btn_Menu ul {
    padding: 0;
}

#logout_Btn_Menu .MuiPaper-elevation1 {
    border-radius: 10px;
}

/* cutom Inputs */
.cstm_Input {
    flex-grow: 1;
    border-radius: 25px;
    border: 1px solid var(--secondry-color);
    padding: 12px 18px;
    font-size: 14px;
    color: #000;
}

.cstm_Input::placeholder {
    color: #949494;
}

.cstm_Input:focus {
    border: 1px solid var(--secondry-color);
    box-shadow: none;
    outline: 0;
}

.upload_input {
    display: flex;
    align-items: center;
    padding: 5px 5px 5px 15px;
    gap: 10px;
    border: 1px dashed #00000070;
    width: 100%;
    border-radius: 35px;
    font-size: 12px;
    color: #000;
    position: relative;
    z-index: 0;
    transition: all 0.3s ease-in-out 0.1s;
}

.upload_input input {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}

.upload_input .file_text {
    flex-grow: 1;
}
textarea {
    resize: none;
  }

.file-select {
    color: var(--secondry-color);
    font-size: 9px;
    font-weight: 400;
    text-transform: uppercase;
    border: 1px solid var(--secondry-color);
    display: inline-block;
    padding: 8px 20px;
    border-radius: 30px;
}

/* Privacy pages */
.privacy_layout {
    border-radius: 18px;
    background: #FFF;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.03);
    padding: 50px;
    min-height: 80vh;
}

.privacy_heading {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    margin-bottom: 50px;
}

.privacy_heading_icon {
    height: 50px;
}

.privacy_heading_icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.privacy_heading h4 {
    color: var(--primary-color);
    text-align: center;
    font-size: 25px;
    font-weight: 700;
    position: relative;
}

.privacy_heading h4:before {
    content: "";
    height: 2px;
    width: 160px;
    top: 50%;
    transform: translateY(-50%);
    background: var(--primary-color);
    position: absolute;
    right: calc(100% + 20px);
}

.privacy_heading h4:after {
    content: "";
    height: 2px;
    width: 160px;
    top: 50%;
    transform: translateY(-50%);
    background: var(--primary-color);
    position: absolute;
    left: calc(100% + 20px);
}

.privacy_content p {
    color: rgba(0, 0, 0, 0.70);
    text-align: justify;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.54px;
}

/* Common Header */
.cmn_hdr {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.cmn_hdr h3 {
    color: #000;
    font-size: 1.4em;
    font-weight: 700;
    text-transform: capitalize;
    display: flex;
    align-items: center;
}

.cmn_hdr a {
    color: #197BBD;
    font-size: 16px;
    font-weight: 600;
    transition: all 0.3s ease 0.1s;
}

.cmn_hdr a:hover {
    transform: translateX(-10px);
}

/* Upcoming Task List */
.task_list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.task_list_item h5 {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    border-radius: 10px;
    background: #FAFAFA;
    padding: 14px 16px;
    color: var(--primary-color);
    font-size: 14px;
    font-weight: 700;
}

.task_list_item h5 svg {
    width: 22px;
    height: 22px;
}

/* Input Fields */
.input_field_Item:not(:last-child) {
    margin-bottom: 40px;
}

.input_label {
    color: var(--secondry-color);
    font-size: 18px;
    font-weight: 700;
    line-height: 1.5em;
    text-transform: capitalize;
}

.input_field input {
    border-radius: 35px;
    border: 2px solid var(--secondry-color);
    background: #FDFDFD;
    padding: 14px 20px;
    width: 100%;
    font-size: 18px;
    line-height: 1.2em;
}

.input_field.icon {
    position: relative;
    display: flex;
}

.input_field.icon img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    cursor: pointer;
}

.input_field.icon input {
    padding-right: 50px;
}

/* Login css */
.lgn_btn_wpr {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    margin-top: 50px;
}

.lgn_btn_wpr button {
    border-radius: 35px;
    border: 1px solid var(--pink-color);
    background: var(--pink-color);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    padding: 16px 24px;
    cursor: pointer;
}

.lgn_btn_wpr button:hover {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    ;
}

.lgn_btn_wpr a {
    color: var(--secondry-color);
    text-align: center;
    font-size: 18px;
    font-weight: 700;
}

.lgn_btn_wpr a:hover {
    color: var(--primary-color);
}


/* Form Css */
.form_row {
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 40px;
}

.input_wpr {
    flex: 0 0 100%;
}

.form_row .input_wpr {
    flex: 1;
    display: inline-flex;
    flex-direction: column;
    gap: 8px;
}

.form_row.item2 .input_wpr {
    flex: 0 0 calc(100% / 2 - 20px);
}

.form_row .input_wpr label {
    color: var(--secondry-color);
    font-size: 16px;
    font-weight: 700;
}

.input_wpr input {
    border-radius: 24px;
    border: 1px solid #9E9E9E;
    background: #FDFDFD;
    padding: 14px 20px;
    width: 100%;
    font-size: 14px;
    color: #000;
}

.input_wpr.text_area {
    flex: 0 0 100% !important;
}

.input_wpr.text_area textarea {
    border-radius: 24px;
    border: 1px solid #9E9E9E;
    background: #FDFDFD;
    padding: 14px 20px;
    width: 100%;
    font-size: 14px;
    color: #000;
    min-height: 100px;
    resize: none;
}

input:-internal-autofill-selected {
    background-color: #0000 !important;
}

.form_row.item2 .input_wpr input::placeholder {
    color: #949494;
}

.input_wpr.select__bx fieldset {
    border-radius: 30px;
    border-color: #9E9E9E;
}

.input_wpr.select__bx>div>div {
    padding-top: 12px;
    padding-bottom: 13px;
    font-size: 14px;
    padding-left: 20px;
    color: #b2b4b2;
}

.input_wpr.select__bx fieldset:focus,
.input_wpr.text_area textarea:focus,
.form_row.item2 .input_wpr input:focus {
    border-color: var(--secondry-color);
}

.input_wpr div,
.error_msg {
    font-size: 12px;
    color: #c32b2b;
    font-weight: 700;
}
.error_msg{
    margin-top: 5px;
}
.input_wpr select {
    border-radius: 24px;
    border: 1px solid #9E9E9E;
    background: #FDFDFD;
    padding: 14px 20px;
    width: 100%;
    font-size: 14px;
    color: #000;
}

.input_wpr.bg-select select {
    border-radius: 24px;
    color: #9E9E9E;
    background: #F5F5F5;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.03);
}

.input_wpr select option {
    color: #000;
}

/* Simple Tabbing */
.smpl_tab_btns {
    display: flex;
    gap: 15px;
}

.smpl_tab_btns li {
    border-radius: 27.5px;
    border: 1px solid #fff;
    background: #fff;
    padding: 15px 20px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.smpl_tab_btns li.active {
    background-color: var(--secondry-color);
    border-color: var(--secondry-color);
    color: #fff;
}

/* Pie chart  */
.piechart_wpr_out {
    filter: drop-shadow(0px 10px 35px rgba(0, 0, 0, 0.03));
    background: #fff;
    border-radius: 20px;
    min-height: 260px;
    padding: 20px 15px;
}

.piechart_wpr {
    display: flex;
    align-items: center;
    justify-content: center;
}

.piechart_wpr_out h5 {
    color: #243465;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.3em;
    letter-spacing: 0.277px;
}

/* Term And Cndition */
.privacy_layout_wpr {
    padding: 0 40px;
    position: relative;
}

.pri_icn_wpr {
    display: flex;
    align-items: center;
}

.privacy_view_icon {
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
}

.view_pg_inr h2 {
    font-size: 32px;
    line-height: 1.2em;
    margin-bottom: 20px;
}

.view_pg_inr h4 {
    font-size: 24px;
    line-height: 1.2em;
    margin-bottom: 15px;
}

.view_pg_inr p {
    font-size: 16px;
    line-height: 1.5em;
    margin-bottom: 10px;
}

.jodit-wysiwyg {
    /* height: 50vh !important; */
}

/* Filter List Buttons */
.filter_bar {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
}

.date_wpr {
    position: relative;
    border-radius: 29.5px;
    background: #FFF;
    padding: 10px 20px;
    color: #666666;
}

.date_wpr input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}
.date_wpr input[type="date"]::-webkit-calendar-picker-indicator {
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.date_inr {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.filter_bar .select__bx {
    width: 160px;
}

.filter_bar .select__bx .MuiSelect-select {
    padding: 10px;
    background: #fff;
    border-radius: 30px;
    color: #666;
}

.filter_bar .select__bx fieldset {
    border-radius: 30px;
    border: 0 !important;
}

/* Save Button Position */
.save_btn {
    position: relative;
}

.save_btn button {
    position: absolute;
    bottom: calc(100% + 30px);
    right: 0;
}

.save_btn_spc {
    padding-right: 100px;
}


.tab-center{
    width: 100%;
    display: flex;
    padding: 20px 0;
    justify-content: center;
    align-items: center;
}