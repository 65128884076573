.table_wpr {
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 31px 0px rgba(44, 50, 63, 0.02);
    padding: 24px;
}
.table_hdr {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
    margin-bottom: 30px;
}
.search_wpr {
    position: relative;
    display: flex;
    align-items: center;
    flex: 0 0 300px;
}

.search_wpr span {
    display: inline-block;
    position: absolute;
    right: 10px;
    cursor: pointer;
}

.search_wpr input {
    background: #F8F9FA;
    border: 0;
    padding: 12px 50px 12px 20px;
    width: 100%;
    border-radius: 30px;
}
.table_responsive {
    overflow-x: auto;
}
.table {
    width: 100%;
}
th {
    color: #495057;
    font-size: 0.9em;
    font-weight: 700;
    border-bottom: 1px solid #EFF2F7;
    background: #F8F9FA;
    padding: 10px;
    text-align: left;
}
td {
    padding: 12px 10px;
    text-align: left;
    color: #666666;
    font-size: 14px;
}
tbody tr:nth-child(even) td {
    background-color: #FAFAFA;
}
.pr_icn_wpr {
    display: flex;
    align-items: center;
    gap: 10px;
}

.pr_icn {
    display: inline-flex;
    width: 25px;
    flex: 0 0 25px;
    height: 25px;
}

.block_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    font-weight: 700;
    border-radius: 6px;
    font-size: 12px;
    padding: 4px;
    cursor: pointer;
}
.block_btn.unblock_btn {
    background: var(--primary-color);
    color: #fff;
}

.action_btn_wpr {
    display: flex;
    align-items: center;
    gap: 8px;
}
.action_btn_wpr li {
    width: 24px;
    height: 24px;
    flex: 0 0 24px;
    cursor: pointer;
}
.action_btn_wpr li:hover img{
    transform: rotateY(180deg);
}

/* Pagination */
nav {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
nav ul {
    border-radius: 4px;
    background: #F8F9FD;
    padding: 6px !important;
}
nav ul li button.Mui-selected {
    background: var(--pink-color) !important;
    color: #fff !important;
    border-radius: 8px;
    font-weight: 600;
    font-family: var(--font-family);
}