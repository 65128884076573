.auth_wpr {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.auth_inr_wpr {
    display: flex;
    max-width: 1400px;
    margin: 0px auto;
    width: 100%;
    border-radius: 20px;
    background-size: contain;
    transform: scale(70%);
}

.authLeft,
.auth_rgt {
    flex: 0 0 50%;
}

.authLeft img {
    width: 100%;
    border-radius: 20px 0 0 20px;
}

.auth_rgt {
    padding: 80px 65px;
    border-radius: 150px 20px 20px 0;
    background: #fff;
}

.auth_ctnt {
    text-align: center;
    margin-bottom: 50px;
}

.auth_ctnt img {
    max-width: 260px;
    margin: 0 auto;
    width: 100%;
}

.auth_ctnt h4 {
    color: #000;
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    font-family: Arial;
    margin: 60px 0 24px;
}

.auth_ctnt h6 {
    color: var(--secondry-color);
    text-align: center;
    font-size: 28px;
    font-weight: 700;
}

.auth_rgt .error_msg{
    font-size: 14px;
}
/* Media query */
@media(min-width: 1800px) {
    .auth_inr_wpr {
        transform: scale(80%);
    }
}