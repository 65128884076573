.cstm_mdl {
    position: fixed;
    z-index: 999999999;
    background: #0000009c;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
}

.mdl_inr {
    padding: 30px;
    border-radius: 10px;
    background: #FFF;
    position: relative;
}

.mdl_hdr {
    color: #000;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    line-height: normal;
}

.img_hdr {
    display: flex;
    align-items: center;
    gap: 12px;
}

.cross_icon {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 20px;
    cursor: pointer;
}

.mdl_inr.addClass_mdl {
    width: 100%;
    max-width: 600px;
    min-height: 300px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mdl_inr form {
    display: flex;
    flex-direction: column;
}

.mdl_ctnt h5 {
    color: #000;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
}

.img_overlay {
    background-image: url('../assets/images/modalBG.svg');
    background-position: left bottom;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 50px;
    max-width: 450px;
    width: 100%;
}

.btn-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

/* Voice Modal */
.voice_mdl {
    max-width: 600px;
    width: 100%;
}

.tone_list {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 30px;
}

.tone_list li {
    position: relative;
}

.tone_list li input {
    position: absolute;
    width: 100%;
    height: 100%;
    visibility: hidden;
}

.tone_list li label {
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 10px;
    border: 1px solid #EAEAEA;
    background: #FFF;
    padding: 8px 10px;
    font-size: 14px;
    cursor: pointer;
}

.tone_list li input:checked+label {
    border-color: var(--pink-color);
}