@font-face {
    font-family: 'Arial CE';
    src: url('ArialCE-BoldItalic.woff2') format('woff2'),
        url('ArialCE-BoldItalic.woff') format('woff'),
        url('ArialCE-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Arial CE';
    src: url('ArialCE-Italic.woff2') format('woff2'),
        url('ArialCE-Italic.woff') format('woff'),
        url('ArialCE-Italic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Arial CE';
    src: url('ArialCE.woff2') format('woff2'),
        url('ArialCE.woff') format('woff'),
        url('ArialCE.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arial CE';
    src: url('Arial-BoldMT.woff2') format('woff2'),
        url('Arial-BoldMT.woff') format('woff'),
        url('Arial-BoldMT.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arial CE MT';
    src: url('ArialCEMTBlack-Regular.woff2') format('woff2'),
        url('ArialCEMTBlack-Regular.woff') format('woff'),
        url('ArialCEMTBlack-Regular.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arial';
    src: url('Arial-Lgt.woff2') format('woff2'),
        url('Arial-Lgt.woff') format('woff'),
        url('Arial-Lgt.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arial';
    src: url('Arial-LgtItl.woff2') format('woff2'),
        url('Arial-LgtItl.woff') format('woff'),
        url('Arial-LgtItl.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Arial';
    src: url('Arial-ItalicMT.woff2') format('woff2'),
        url('Arial-ItalicMT.woff') format('woff'),
        url('Arial-ItalicMT.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Arial';
    src: url('Arial-ItalicMT_1.woff2') format('woff2'),
        url('Arial-ItalicMT_1.woff') format('woff'),
        url('Arial-ItalicMT_1.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Arial';
    src: url('Arial-Mdm.woff2') format('woff2'),
        url('Arial-Mdm.woff') format('woff'),
        url('Arial-Mdm.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arial';
    src: url('Arial-MdmItl.woff2') format('woff2'),
        url('Arial-MdmItl.woff') format('woff'),
        url('Arial-MdmItl.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Arial Narrow';
    src: url('ArialNarrow-Bold.woff2') format('woff2'),
        url('ArialNarrow-Bold.woff') format('woff'),
        url('ArialNarrow-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arial Narrow';
    src: url('ArialNarrow-BoldItalic.woff2') format('woff2'),
        url('ArialNarrow-BoldItalic.woff') format('woff'),
        url('ArialNarrow-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Arial Narrow';
    src: url('ArialNarrow.woff2') format('woff2'),
        url('ArialNarrow.woff') format('woff'),
        url('ArialNarrow.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arial';
    src: url('Arial-Black.woff2') format('woff2'),
        url('Arial-Black.woff') format('woff'),
        url('Arial-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arial Narrow';
    src: url('ArialNarrow-Italic.woff2') format('woff2'),
        url('ArialNarrow-Italic.woff') format('woff'),
        url('ArialNarrow-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Arial';
    src: url('ArialMT.woff2') format('woff2'),
        url('ArialMT.woff') format('woff'),
        url('ArialMT.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arial';
    src: url('Arial-BoldMT_1.woff2') format('woff2'),
        url('Arial-BoldMT_1.woff') format('woff'),
        url('Arial-BoldMT_1.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arial';
    src: url('Arial-BoldMT_2.woff2') format('woff2'),
        url('Arial-BoldMT_2.woff') format('woff'),
        url('Arial-BoldMT_2.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arial';
    src: url('Arial-BoldItalicMT.woff2') format('woff2'),
        url('Arial-BoldItalicMT.woff') format('woff'),
        url('Arial-BoldItalicMT.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Arial';
    src: url('Arial-BoldItalicMT_1.woff2') format('woff2'),
        url('Arial-BoldItalicMT_1.woff') format('woff'),
        url('Arial-BoldItalicMT_1.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Arial';
    src: url('Arial-BlackItalic.woff2') format('woff2'),
        url('Arial-BlackItalic.woff') format('woff'),
        url('Arial-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

